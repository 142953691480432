<template>
	<div class="npc">
		<p class="title">Non Player Characters</p>

		<div v-if="npcs_government.length > 0" class="group">
			<p class="group-title">Government</p>
			<div class="characters">
				<ui-link v-for="npc in npcs_government" :to="'/game/profile/npc/' + npc.id" :key="npc.id" class="character">
					<npc-character :character="npc" />
				</ui-link>
			</div>
		</div>

		<div v-if="npcs_law_enforcement.length > 0" class="group">
			<p class="group-title">Law enforcement</p>
			<div class="characters">
				<ui-link v-for="npc in npcs_law_enforcement" :to="'/game/profile/npc/' + npc.id" :key="npc.id" class="character">
					<npc-character :character="npc" />
				</ui-link>
			</div>
		</div>

		<div v-if="npcs_media.length > 0" class="group">
			<p class="group-title">Media</p>
			<div class="characters">
				<ui-link v-for="npc in npcs_media" :to="'/game/profile/npc/' + npc.id" :key="npc.id" class="character">
					<npc-character :character="npc" />
				</ui-link>
			</div>
		</div>

		<div v-if="npcs_citizens.length > 0" class="group">
			<p class="group-title">Citizens</p>
			<div class="characters">
				<ui-link v-for="npc in npcs_citizens" :to="'/game/profile/npc/' + npc.id" :key="npc.id" class="character">
					<npc-character :character="npc" />
				</ui-link>
			</div>
		</div>

		<div v-if="npcs_others.length > 0" class="group">
			<p class="group-title">Others</p>
			<div class="characters">
				<ui-link v-for="npc in npcs_others" :to="'/game/profile/npc/' + npc.id" :key="npc.id" class="character">
					<npc-character :character="npc" />
				</ui-link>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import NpcCharacter from '@/components/game/profile/details/npc/npc-character'

export default {
	name: 'npc',
	computed: {
		...mapGetters([ 'game' ]),
		npcs_government() {
			return this.game.players.filter((p) => { return p && p.role && p.role.npc && p.role.category == 'Government' })
		},
		npcs_law_enforcement() {
			return this.game.players.filter((p) => { return p && p.role && p.role.npc && p.role.category == 'Law Enforcement' })
		},
		npcs_media() {
			return this.game.players.filter((p) => { return p && p.role && p.role.npc && p.role.category == 'Media' })
		},
		npcs_citizens() {
			return this.game.players.filter((p) => { return p && p.role && p.role.npc && p.role.category == 'Citizens' })
		},
		npcs_others() {
			return this.game.players.filter((p) => { return p && p.role && p.role.npc && p.role.category == 'N/A' })
		}
	},
	components: { NpcCharacter }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.npc
	.group
		margin 0 0 24px 0
		&:last-child
			margin-bottom 0
		.group-title
			margin 0 0 16px 0
			text-transform uppercase
			color blue_lighter
			font-size 1.8rem
			line-height 24px
		.characters
			flex flex-start flex-start 
			flex-wrap wrap
			.character
				width 72px
				width 111px
				margin 0 0 14px 0
				cursor pointer


</style>
