<template>
	<div class="about">
		
		<p class="title">About</p>

		<div class="text">
			<ui-text :text="logged_user.role.about_you" />
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiText from '@/components/ui/ui-text'

export default {
	name: 'about',
	computed: {
		...mapGetters([ 'logged_user' ])
	},
	components: { UiText }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.about
	display block
	.text
		display block
		font-size 1.4rem
		line-height 20px


</style>
