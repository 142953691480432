<template>
	<div class="role">
		<p class="title">{{ logged_user.role.name }}</p>

		<div class="text">
			<ui-text :text="logged_user.role.role_description" />
		</div>

		<!-- <div class="background" :data-category="logged_user.role.category"></div> -->

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiText from '@/components/ui/ui-text'

export default {
	name: 'role',
	computed: {
		...mapGetters([ 'logged_user' ])
	},
	components: { UiText }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.role
	display block
	min-height 100%
	.background
		display block
		width 100%
		margin 32px 0 0 0
		min-height 300px
		background bottom center no-repeat
		background-size contain
		&[data-category="Law Enforcement"]
			background-image url(../../../../assets/img/emblems/law-enforcement.svg)
		&[data-category="Citizens"]
			background-image url(../../../../assets/img/emblems/citizens.svg)
		&[data-category="Government"]
			background-image url(../../../../assets/img/emblems/government.svg)
		&[data-category="Media"]
			background-image url(../../../../assets/img/emblems/media.svg)
	.text
		display block
		font-size 1.4rem
		line-height 20px
		ul
			margin-left 20px
			li
				margin 0 0 16px 0
		p
			margin 0 0 8px 0


</style>
