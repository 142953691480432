<template>
	<div class="npc-character">
		<div class="picture"><span class="status"></span></div>
		<p class="name">{{ character.name }}</p>
		<p class="role">{{ character.role.name }}</p>
	</div>
</template>

<script>
export default {
	name: 'npc-character',
	props: {
		character: { default: false }
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/variables'


.npc-character
	flex center center
	flex-direction column
	width 100%
	text-align center
	line-height 14px
	font-size 1.2rem
	padding 0 4px
	.picture
		width 72px
		height 72px
		margin 0 0 4px 0
		background-color green_light
		border-radius 50%
		.status
			position absolute
			right 0
			top 0
			width 22px
			height 22px
			border-radius 50%
			background blue_dark url(../../../../../assets/img/cancel-gray.svg) center center no-repeat
			background-size 16px 16px
	.name
		margin 0 0 4px 0
		font-weight 700
	.role
		color gray


</style>
