<template>
	<div class="attribute-icon">
		<ui-icon :name="'profile/attributes/' + attribute.slug + '-' + attribute.value" :size="96" />
		<span class="nb">{{ attribute.value }}</span>
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon-info"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'attribute-icon',
	data() {
		return {
			
		}
	},
	props: [ 'attribute' ],
	computed: {
		...mapGetters([ 'app' ]),
	},
	methods: {
		show_attribute(attribute) {
			if (this.app.device == 'mobile') {
				this.selected_attribute = attribute
			}
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/variables'



#app.mobile
	.attribute-icon
		position relative
		.icon-info
			display block
			position absolute
			right 4px
			top calc(50% - 12px)
			height 24px
			width 24px



.attribute-icon
	position absolute
	left 0
	top 0
	width 96px
	height 96px
	.ui-icon
		transform translateX(-8px)
	.nb
		display block
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		font-family 'Josefin Sans'
		line-height (96px + 6px)
		text-align center
		font-size 3.2rem
	.icon-info
		display none


</style>
