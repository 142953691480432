<template>
	<div class="view view-profile" :class="$route.name">
		<div class="side" :class="{ 'has-side': $route.name == 'profile-detail' || $route.name == 'profile-detail-npc' || $route.name == 'profile-detail-attribute' }">
			<div class="scroll" v-scrollbar>
				<div>
					<profile-menu />
				</div>
			</div>
		</div>

		<div v-if="$route.name == 'profile-detail'" class="content">
			<div class="scroll" v-scrollbar>
				<div>
					<profile-detail :el="$route.params.category" />
				</div>
			</div>
			<ui-link to="/game/profile" class="close"><ui-icon name="close-blue-dark" :size="16" /></ui-link>
		</div>

		<div v-if="$route.name == 'profile-detail-npc'" class="content">
			<profile-detail el="npc-group" />
			<ui-link to="/game/profile/npc" class="close"><ui-icon name="close-blue-dark" :size="16" /></ui-link>
		</div>

		<div v-if="$route.name == 'profile-detail-attribute'" class="content">
			<div class="scroll" v-scrollbar>
				<div class="scroll">
					<profile-detail el="attributes" />
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProfileMenu from '@/components/game/profile/profile-menu'
import ProfileDetail from '@/components/game/profile/profile-detail'

export default {
	name: 'view-profile',
	data() {
		return {
			
		}
	},
	computed: {
		...mapGetters([ 'app' ]),
	},
	components: { ProfileMenu, ProfileDetail }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'



#app.mobile
	.view-profile
		&.profile-detail, &.profile-detail-npc, &.profile-detail-attribute
			display block
			.side
				display none !important
		.side, .content
			max-width none
		.side
			margin 0
		.content
			.close
				top 16px
				right 16px

#app.tablet-vertical, #app.tablet-horizontal
	.view-profile
		&.profile-detail, &.profile-detail-npc, &.profile-detail-attribute
			display block
		.side, .content
			max-width none
		.side
			margin 0
			&.has-side
				width 200px
				&:after
					top 40px
					bottom 40px
		.content
			width calc(100% - 200px)
			.close
				top 32px
				right 32px

#app.tablet-horizontal
	.view-profile
		.side
			&.has-side
				width 370px
		.content
			width calc(100% - 370px)
			.close
				top 32px
				right 32px
				
.view-profile
	display block

.side
	float left
	height 100%
	width 100%
	max-width 415px
	margin 0 16px
	// &.has-side
	// 	&:after
	// 		content ''
	// 		position absolute
	// 		right 0
	// 		top 80px
	// 		bottom 80px
	// 		border-right 2px solid blue_lighter
.content
	float left
	height 100%
	width 100%
	max-width 600px
	.close
		position absolute
		right 48px
		top 74px
		height 40px
		width 40px
		cursor pointer
		border-radius 50%
		background var(--color-close-button-bg)
		color var(--color-close-button-text)
		flex center center
		transition transform 0.1s easeOutQuart
		&:hover
			transform scale(1.05)
		&:active
			transform scale(0.95)

</style>
