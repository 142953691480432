<template>
	<div class="profile-menu">
		<div class="menu">
			<main-title icon="menu/menu-profile" :text="logged_user.name" :closable="$route.name === 'profile'" />
			<p class="sub-title">{{ logged_user.role.name }}</p>
			
			<div class="hexagons">
				<ui-link to="/game/profile/attributes" class="hex attributes">
					<span class="hex-1"><span class="hex-2">
						<ui-icon class="icon" name="profile/profile-attributes" :size="40" />
						<span class="text">values</span>
					</span></span>
				</ui-link>
				<ui-link to="/game/profile/about" class="hex about">
					<span class="hex-1"><span class="hex-2">
						<ui-icon class="icon" name="profile/profile-about" :size="40" />
						<span class="text">about</span>
					</span></span>
				</ui-link>
				<ui-link to="/game/profile/role" class="hex role">
					<span class="hex-1"><span class="hex-2">
						<ui-icon class="icon" name="profile/profile-role" :size="40" />
						<span class="text">role</span>
					</span></span>
				</ui-link>
				<ui-link to="/game/profile/goals" class="hex goals">
					<span class="hex-1"><span class="hex-2">
						<ui-icon class="icon" name="profile/profile-goals" :size="40" />
						<span class="text">goals</span>
					</span></span>
				</ui-link>
				<!-- <ui-link to="/game/profile/connections" class="hex connections">
					<span class="hex-1"><span class="hex-2">
						<ui-icon class="icon" name="profile/profile-connections" :size="40" />
						<span class="text">connections</span>
					</span></span>
				</ui-link> -->
				<!-- <ui-link to="/game/profile/npc" class="hex npc" :class="{ 'exact-active': $route.name == 'profile-detail-npc' }">
					<span class="hex-1"><span class="hex-2">
						<ui-icon class="icon" name="profile/profile-npc" :size="40" />
						<span class="text">npc</span>
					</span></span>
				</ui-link> -->

				<div class="hex video-tutorial" v-if="game.scenario.tutorial" v-hammer:tap="() => play_video('tutorial')">
					<span class="hex-1"><span class="hex-2">
						<ui-icon class="icon" name="profile/profile-video" :size="32" />
						<span class="text">Watch<br>tutorial</span>
					</span></span>
				</div>
				<div class="hex video-intro" v-if="intro_visible && game.scenario.intro" v-hammer:tap="() => play_video('intro')">
					<span class="hex-1"><span class="hex-2">
						<ui-icon class="icon" name="profile/profile-video" :size="32" />
						<span class="text">Watch<br>intro</span>
					</span></span>
				</div>

				<div v-hammer:tap="() => logout()" class="hex logout">
					<span class="hex-1"><span class="hex-2">
						<ui-icon class="icon" name="logout" :size="40" />
						<span class="text">Log out</span>
					</span></span>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import MainTitle from '@/components/game/main-title'

export default {
	name: 'profile-menu',
	data() {
		return {
			
		}
	},
	computed: {
		...mapGetters([ 'logged_user', 'game' ]),
		intro_visible() {
			return (this.game.room.state === 'running' || this.game.room.state === 'paused') || (this.logged_user && this.logged_user.video_status && this.logged_user.video_status.intro)
		}
	},
	methods: {
		logout() {
			this.$store.dispatch('logout')
		},
		async play_video(slug) {
			this.$store.commit('EDIT_GAME_STATE', { playing_video: slug })
		}
	},
	components: { MainTitle }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


#app.mobile
	.profile-menu
		.hexagons
			overflow hidden
			height 325px
			width 348px
			transform translateX(-16px)
			margin 0 auto
			margin-top 64px
			.hex
				width 96px
				height (@width * 2)
				font-size 1rem
				mask-image radial-gradient(white, black)
				&.attributes
					left (80px - 32px)
					top (64px - 48px)
				&.about
					left (187px - 32px)
					top (64px - 48px)
				&.role
					left (134px - 32px)
					top (153px - 48px)
				&.goals
					left (239px - 32px)
					top (153px - 48px)
				&.connections
					left (187px - 32px)
					top (243px - 48px)
				&.npc
					left (80px - 48px)
					top (243px - 32px)
				&.video-tutorial
					left (80px - 48px)
					top (243px - 32px)
				&.video-intro
					left (187px - 48px)
					top (243px - 32px)
				&.logout
					left 264px
					top (243px - 48px)
					width 80px
					.hex-1 .hex-2 .text
						margin-top 0
				.hex-1
					mask-image radial-gradient(white, black)

#app.tablet-vertical, #app.tablet-horizontal
	.side
		&:not(.has-side)
			.profile-menu .menu .hexagons .hex
				&.video-tutorial
					top 349px
					left 70px
		&.has-side
			.profile-menu
				width 100%
				padding 16px
				.menu
					width 100%
					height 100%
					>>> .main-title, >>> .sub-title
						display none
					.hexagons
						width 100%
						height (24px + 110px * 6 + 64px - 120px)
						.hex
							width 104px
							height (@width * 2)
							&.attributes
								left 0
								top 24px
							&.about
								left 40px
								top (24px + 110px)
							&.role
								left 0
								top (24px + 110px * 2)
							&.goals
								left 40px
								top (24px + 110px * 3)
							&.connections
								left 0
								top (24px + 110px * 4)
							&.npc
								left 40px
								top (24px + 110px * 5)
	.profile-menu
		padding 32px
		.hexagons
			overflow hidden
			height 550px
			width 530px
			margin 0 auto
			.hex
				width 134px
				height (@width * 2)
				transition 0.25s easeOutQuart
				&.attributes
					left (96px)
					top (64px)
				&.about
					left (96px + 134px + 16px)
					top (64px)
				&.role
					left (134px + 38px)
					top (194px)
				&.goals
					left (134px + 38px + 134px + 16px)
					top (194px)
				&.connections
					left (96px + 134px + 16px)
					top (327px)
				&.npc
					left (96px - 32px)
					top (327px + 32px)
				&.video-intro
					left (96px + 124px)
					top (349px)
				&.logout
					left (134px + 38px + 134px + 16px + 90px)
					top 330px
					width 100px

#app.tablet-horizontal
	.side
		&.has-side
			.profile-menu
				padding 32px
				.menu
					.hexagons
						.hex
							width 104px
							height (@width * 2)
							&.attributes
								left (104px / 2 + 8px)
								top 24px
							&.about
								left 0px
								top 124px
							&.role
								left (104px + 16px)
								top 124px
							&.goals
								left (104px / 2 + 8px)
								top 224px
							&.connections
								left (104px + 104px / 2 + 24px)
								top 224px
							&.npc
								left (104px + 16px)
								top 344px
			
#app.not-touch
	.profile-menu
		.hexagons
			.hex
				&.logout
					.hex-1 .hex-2:hover
						background-color var(--color-profile-hexagon-logout-bg-hover)
						color var(--color-profile-hexagon-logout-text)
				.hex-1
					.hex-2:hover
						background-color var(--color-profile-hexagon-active-bg)
						color var(--color-profile-hexagon-active-text)

#app.touch
	.profile-menu
		.hexagons
			.hex
				mask-image radial-gradient(white, black)
				.hex-1
					mask-image radial-gradient(white, black)

#app
	.side.has-side
		.profile-menu .hexagons
			.hex.video-tutorial, .hex.video-intro, .hex.logout
				display none

.profile-menu
	width 100%
	padding 16px
	height 100%
	font-family 'Josefin Sans', sans-serif
	.sub-title
		position absolute
		left 56px
		top 48px
		color var(--color-primary)
		font-family 'Poppins'
		font-size 1.6rem
		user-select none
	.hexagons
		height 100%
		.hex
			position absolute
			left 0
			top 0
			width 134px
			height (@width * 2)
			margin -42px 0
			transform rotate(120deg)
			cursor pointer
			// visibility hidden
			pointer-events none
			overflow hidden
			// mask-image radial-gradient(white, black)
			font-weight 700
			text-transform uppercase
			font-size 1.2rem
			mask-image radial-gradient(white, black)
			decal = 120px
			&.attributes
				left 80px
				top (80px - 40px)
			&.about
				left 8px
				top (206px - 40px)
			&.role
				left 153px
				top (206px - 40px)
			&.goals
				left 80px
				top (332px - 40px)
			&.connections
				left 226px
				top (332px - 40px)
			&.npc
				left 153px
				top (457px - 40px + 40px)
			&.video-tutorial
				// left 74px
				// top (640px - 40px + 52px - decal)
				left 256px
				top (550px - 40px + 52px - decal)
				width 110px
				.hex-1 .hex-2 .text
					margin 4px 0 0 0
			&.video-intro
				left (153px - 18px)
				top (550px - 40px + 52px - decal)
				width 110px
				.hex-1 .hex-2 .text
					margin 4px 0 0 0
			&.logout
				left 24px
				top (446px - 40px + 40px + 20px + 96px - decal)
				width 96px
				&:active .hex-1 .hex-2
					background-color var(--color-profile-hexagon-logout-bg)
					color var(--color-profile-hexagon-logout-text)
				.hex-1 .hex-2
					background-color var(--color-profile-hexagon-logout-bg)
					color var(--color-profile-hexagon-logout-text)
			&.exact-active, &:active
				.hex-1 .hex-2
					background-color var(--color-profile-hexagon-active-bg)
					color var(--color-profile-hexagon-active-text)
			.hex-1
				transform rotate(-60deg)
				// visibility hidden
				overflow hidden
				pointer-events none
				display block
				width 100%
				height 100%
				text-align center
				mask-image radial-gradient(white, black)
				.hex-2
					transform rotate(-60deg)
					width 100%
					height 100%
					// visibility visible
					pointer-events all
					background var(--color-profile-hexagon-bg)
					display block
					overflow hidden
					flex center center
					flex-direction column
					color var(--color-profile-hexagon-text)
					.text
						display block
						margin 8px 0 0 0
			

</style>
