<template>
	<div v-if="is_valid_component" class="profile-detail" :class="el">
		
		<component :is="el" />

	</div>
</template>

<script>
import Goals from '@/components/game/profile/details/goals'
import About from '@/components/game/profile/details/about'
import Role from '@/components/game/profile/details/role'
import Attributes from '@/components/game/profile/details/attributes'
import Connections from '@/components/game/profile/details/connections'
import Npc from '@/components/game/profile/details/npc'
import NpcGroup from '@/components/game/profile/details/npc-group'

export default {
	name: 'profile-detail',
	props: {
		el: { default: false }
	},
	computed: {
		is_valid_component() {
			return (['goals', 'about', 'role', 'attributes', 'connections', 'npc', 'npc-group'].includes(this.el))
		}
	},
	components: { Goals, About, Role, Attributes, Connections, Npc, NpcGroup }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


#app.mobile
	.profile-detail
		margin 40px 0
		padding 0 20px
		height calc(100% - 80px)
		border none
		&.npc-group
			margin 0
			padding 0
			height 100%
		&.attributes
			margin 0
			height 100%
		&.connections
			margin-bottom 0
			height calc(100% - 40px)

#app.tablet-vertical
	.profile-detail
		margin 40px 0
		padding 0 20px
		height calc(100% - 80px)


.profile-detail
	width 100%
	width 100%
	height calc(100% - 160px)
	margin 80px 0
	padding 0 40px
	&.npc-group
		margin 8px 0
		padding 0
		height calc(100% - 16px)
	>>> .title
		display block
		margin 0 0 32px 0
		padding 4px 40px 0 0
		color var(--color-primary)
		font-size 2.4rem
		line-height (24px + 4px)
		text-transform uppercase
		font-family 'Josefin Sans'

</style>
