<template>
	<div class="goals">
		<p class="title">Goals</p>
		<goals-content />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import GoalsContent from '@/components/game/goals/goals-content'

export default {
	name: 'goals',
	computed: {
		// ...mapGetters([ 'logged_user' ])
	},
	// mounted() {
	// 	this.$el.querySelectorAll('.text .ui-text ul li').forEach((li) => {
	// 		li.addEventListener('click', () => {
	// 			if (li.classList.contains('checked')) li.classList.remove('checked')
	// 			else li.classList.add('checked')
	// 		})
	// 	})
	// },
	components: { GoalsContent }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.goals
	display block
	.text
		>>> .ui-text
			ul
				list-style none
				margin-left 40px
				li
					cursor pointer
					&.checked:after
						content ''
						position absolute
						left -44px
						top -4px
						width 40px
						height 40px
						background url(../../../../assets/img/profile/goal-check.svg) center center no-repeat
						background-size 100% auto
					&:before
						content ''
						position absolute
						left -40px
						top 0
						width 32px
						height 32px
						box-shadow inset 0 0 0 3px blue_lighter
						transform translateY(2px)
	.items
		.item
			margin 0 0 32px 0
			padding 0 0 0 48px
			.chk
				position absolute
				left 0
				top 0
				width 32px
				height 32px
				box-shadow inset 0 0 0 3px blue_lighter
				transform translateY(2px)
				&.checked:after
					content ''
					position absolute
					left -4px
					top -8px
					width 40px
					height 40px
					background url(../../../../assets/img/profile/goal-check.svg) center center no-repeat
					background-size 100% auto
			.text
				display block
				font-size 1.4rem
				line-height 20px


</style>
