<template>
	<div class="connections">
		<p class="title">Connections</p>
		
		<div v-for="group in groups" v-if="group.players.length > 0" class="group">
			<div class="group-title"><span v-if="group.value > 0">+</span>{{ group.value }} • {{ group.title }}</div>
			<div class="group-description">{{ group.description }}</div>
			<div class="group-players">
				<div v-for="player in group.players" class="player">
					<div class="avatar">
						<div class="img" v-bg="{ name: player.avatar, type: 'avatar' }"></div>
					</div>
					<div class="player-content">
						<div class="name">{{ player.name }} <span v-if="player.role.npc" class="npc">(IA)</span></div>
						<div class="description">{{ player.student_name }}</div>
						<div class="description">{{ player.role.name }}</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'connections',
	data() {
		return {
			groups: [
				{ value: 3, title: "Close friend", description: "You know each other well or for many years and trust each other with secrets. You can count on their support.", players: [] },
				{ value: 2, title: "Friend", description: "You hang out with each other and get along with them on a personal as well as professional level.", players: [] },
				{ value: 1, title: "Acquaintance positive", description: "You know this person but don't hang out. You trust their motives and competence and can work together as part of their team.", players: [] },
				{ value: 0, title: "Neutral", description: "You either do not know this person or have not formed a relationship or opinion of them.", players: [] },
				{ value: -1, title: "Acquaintance negative", description: "You know this person but prefer not to have to talk to them or be on a team with them. You neither like nor trust them.", players: [] },
				{ value: -2, title: "Rival", description: "You do not get along with this person, but do respect their abilities. You want to be better than them professionally, politically, and/or personally.", players: [] },
				{ value: -3, title: "Enemy", description: "You actively dislike this person and will work against their interests. You were harmed by them in the past.", players: [] },
			]
		}
	},
	computed: {
		...mapGetters([ 'game', 'logged_user' ]),
	},
	created() {
		if (this.logged_user && this.logged_user.role && this.logged_user.role.relations) {
			this.logged_user.role.relations.forEach((relation) => {
				let group = this.groups.find((g) => g.value === relation.score)
				if (group && group.value) {
					let player = this.game.players.find((p) => {
						return p.role && parseInt(p.role.id) === parseInt(relation.relation_id)
					})
					if (player) group.players.push(player)
				}
			})
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


// #app.mobile
// 	.connections
// 		.title
// 			margin-bottom 16px
// 		.legend
// 			margin 24px 0 0 0
// 			max-width none
// 			.legend-title
// 				text-align center
// 			.legend-items
// 				flex center center
// 				.legend-item
// 					margin 0
// 					display inline-block
// 					width 33.3333%
// 					text-align left
// 					.bullet
// 						margin 0 4px 0 0 


.connections
	.group
		margin 0 0 40px 0
		.group-title
			margin 0 0 4px 0
			color blue_lighter
			text-transform uppercase
			font-size 1.6rem
		.group-description
			margin 0 0 8px 0
			padding 0 0 16px 0
			border-bottom 2px solid blue_lighter
			color gray_light
		.group-players
			.player
				padding 8px 0
				min-height 88px
				.avatar
					float left
					width 72px
					height 72px
					border-radius 50%
					.img
						absolute 0
						background blue center center no-repeat
						background-size contain
						border-radius 50%
				.player-content
					margin-left 88px
					line-height 20px
					.name
						margin 0 0 4px 0
						color blue_lighter
						text-transform uppercase
					.description
						margin 0 0 4px 0
						color gray_light
	
</style>
