<template>
	<div class="npc-group">
		<div class="npc-group-content">
			
			<hooper class="slider-main" group="group" ref="carousel">
				<slide v-for="character in characters" :key="character.id">
					<div class="character">
						<div class="character-head">
							<div class="picture"><span class="status"></span></div>
							<div class="content">
								<p class="name">{{ character.name }}</p>
								<p class="function">{{ character.role.name }}</p>
								<p class="status">currently unavailable</p>
							</div>
						</div>
						<div class="character-body">
							<ui-text :text="character.role.about_you" />
							<ui-text :text="character.role.role_description" />
						</div>
					</div>
				</slide>
				<!-- <hooper-navigation slot="hooper-addons"></hooper-navigation> -->
			</hooper>

			<div class="separator"></div>
			
			<hooper class="slider-bullets" group="group" :itemsToShow="4" :centerMode="true">
				<slide v-for="(character, i) in characters" :key="character.id" v-hammer:tap="() => slideTo(i)">
					<npc-character :character="character" />
				</slide>
				<hooper-navigation slot="hooper-addons"></hooper-navigation>
			</hooper>

		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import NpcCharacter from '@/components/game/profile/details/npc/npc-character'
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
import 'hooper/dist/hooper.css'
import UiText from '@/components/ui/ui-text'

export default {
	name: 'npc-group',
	data() {
		return {
			
		}
	},
	computed: {
		...mapGetters([ 'game' ]),
		characters() {
			return this.game.players.filter((p) => { return p && p.role.npc })
			// if (this.character) {
			// 	return this.game.players.filter((p) => { return p && p.category == this.character.category })
			// }
			// return []
		}
	},
	methods: {
		slideTo(n) {
			this.$refs.carousel.slideTo(n-1)
		}
	},
	mounted() {
		if (this.$route.params.npc_id) {
			let index = this.game.players.findIndex((p) => { return p && p.id == this.$route.params.npc_id })
			this.slideTo(index+1)
		}
	},
	components: { NpcCharacter, Hooper, Slide, HooperNavigation, UiText }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'

#app.mobile, #app.tablet-vertical
	.npc-group
		padding 16px 0 0 0
		.npc-group-content
			padding 0
			>>> .hooper
				.hooper-navigation
					.hooper-prev 
						left 8px
					.hooper-next
						right 8px
	.character
		padding 8px 16px
		.character-head
			min-height 72px
			margin-bottom 8px
			.picture
				width 72px
				height 72px
				.status
					width 22px
					height 22px
					background-size 16px 16px
			.content
				margin-left (72px + 16px)
				.name
					margin-bottom 4px
					font-size 2rem
					line-height 32px
				.function
					margin-bottom 4px
					font-size 1.4rem
					line-height 16px
				.status
					text-transform uppercase
					color #979797
		.character-body
			font-size 1.4rem
			line-height 18px


#app.tablet-vertical
	.npc-group
		margin-top 100px
		height calc(100% - 100px)
	.character
		padding 40px
		.character-head
			margin-bottom 32px
		.character-body
			font-size 1.6rem
			line-height 22px
			color gray_light
	

.npc-group
	height 100%
	background-color blue
	user-select none
	padding 72px 0
	// padding 16px 0 0 0
	.npc-group-content
		padding 0 40px
		height 100%
		.slider-main
			height calc(100% - 180px)
		.separator
			height 18px
			margin 16px 0
			border-top 2px solid blue_lighter
			&:after
				content ''
				position absolute
				left calc(50% - 10px)
				top 0
				width 0
				height 0
				border-style solid
				border-width 10px 10px 0 10px
				border-color blue_lighter transparent transparent transparent
		.slider-bullets
			height 130px
			.hooper-slide 
				text-align center
				cursor pointer
				>>> .npc-character
					margin 0 auto
					.picture .status
						background-color blue
			.hooper-navigation
				top 28%


>>> .hooper
	outline none
	.hooper-navigation
		position absolute
		left 0
		top 50%
		right 0
		height 0
		.hooper-prev, .hooper-next
			padding 0
			&:before
				content ''
				position absolute
				top 0
				width 0
				height 0
				border-style solid
				padding 0
			&.hooper-prev
				&:before
					left 8px
					border-width 12px 16px 12px 0
					border-color transparent blue_lighter transparent transparent
			&.hooper-next
				&:before
					right 8px
					border-width 12px 0 12px 16px
					border-color transparent transparent transparent blue_lighter
			svg
				opacity 0
			



.character
	padding 0 8px
	.character-head
		min-height 140px
		margin 0 0 16px 0
		.picture
			float left
			width 140px
			height 140px
			border-radius 50%
			background-color green_light
			.status
				position absolute
				right 0
				top 0
				width 40px
				height 40px
				border-radius 50%
				background blue url(../../../../assets/img/cancel-gray.svg) center center no-repeat
				background-size 32px 32px
		.content
			margin-left 156px
			text-align left
			.name
				margin 0 0 8px 0
				padding 0 48px 0 0
				color blue_lighter
				text-transform uppercase
				font-size 2.4rem
				line-height 32px
			.function
				margin 0 0 16px 0
				font-size 1.8rem
				line-height 20px
			.status
				text-transform uppercase
				color #979797
	.character-body
		display block
		font-size 1.4rem
		line-height 24px
		text-align left


</style>
